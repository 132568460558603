import Flags from 'assets/geography/flags';
import Regions from 'assets/geography/regions';
import { ProductScaleEnum } from 'common/constants/enum';
import colors from 'common/styles/colors';
import * as React from 'react';

import SIMOutline from './sim-outline';
import ESIMComponentStyles, {
  DEFAULT_SIM_OUTLINE_HEIGHT,
  DEFAULT_SIM_OUTLINE_PADDING,
  DEFAULT_SIM_OUTLINE_WIDTH,
} from './styles.css';

export enum EsimComponentTheme {
  Grey = 'grey',
  Dark = 'dark',
  Default = 'default',
}

interface Props {
  theme?: EsimComponentTheme;
  areaCode: string;
  scale: ProductScaleEnum;
  size?: number;
}

export default function ESIMComponent(props: Props) {
  const { theme = EsimComponentTheme.Default, areaCode, scale, size } = props;

  const ratio = DEFAULT_SIM_OUTLINE_WIDTH / DEFAULT_SIM_OUTLINE_HEIGHT;

  const outlineSize = {
    width: size ?? DEFAULT_SIM_OUTLINE_WIDTH,
    height: size ? size * ratio : DEFAULT_SIM_OUTLINE_HEIGHT,
  };
  const childrenSize = {
    width: outlineSize.width - DEFAULT_SIM_OUTLINE_PADDING * 2,
    height: outlineSize.height - DEFAULT_SIM_OUTLINE_PADDING * 2,
    radius: 4,
  };

  return (
    <div className={ESIMComponentStyles.container} style={outlineSize}>
      <SIMOutline
        strokeColor={
          theme === EsimComponentTheme.Dark
            ? colors.mainWhite
            : theme === EsimComponentTheme.Grey
              ? colors.foregroundTertiary
              : colors.mainBlack
        }
        height={outlineSize.height}
        width={outlineSize.width}
        className={ESIMComponentStyles.outline}
      />
      <div className={ESIMComponentStyles.childrenContainer}>
        {scale === ProductScaleEnum.Local ? (
          <Flags iso={areaCode} {...childrenSize} />
        ) : (
          <Regions iso={areaCode} {...childrenSize} />
        )}
      </div>
    </div>
  );
}
