import * as React from 'react';

import {
  DEFAULT_SIM_OUTLINE_HEIGHT,
  DEFAULT_SIM_OUTLINE_WIDTH,
} from './styles.css';

interface Props extends React.SVGProps<SVGSVGElement> {
  strokeColor?: string;
}

export default function SIMOutline(props: Props) {
  const { strokeColor = '#000', ...restProps } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={DEFAULT_SIM_OUTLINE_WIDTH}
      height={DEFAULT_SIM_OUTLINE_HEIGHT}
      fill="none"
      viewBox="0 0 52 41"
      {...restProps}
    >
      <path
        stroke={strokeColor}
        d="M45 1a6 6 0 016 6v17.785a6 6 0 01-1.439 3.899l-7.876 9.214A6 6 0 0137.125 40H7a6 6 0 01-6-6V7a6 6 0 016-6h38z"
      />
    </svg>
  );
}
