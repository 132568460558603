import { Tooltip } from '@mantine/core';
import { InfoIcon } from 'common/assets';
import colors from 'common/styles/colors';
import React from 'react';

interface PopoverProps
  extends Omit<React.ComponentProps<typeof Tooltip>, 'children'> {
  children?: React.ReactNode;
}

export default function Popover(props: PopoverProps) {
  return (
    <Tooltip
      events={{
        focus: true,
        hover: true,
        touch: true,
      }}
      maw={250}
      withArrow
      multiline
      arrowSize={8}
      ta="center"
      {...props}
    >
      {props.children ?? (
        <InfoIcon size={16} color={colors.foregroundTertiary} />
      )}
    </Tooltip>
  );
}

export function interceptClickEvents(e) {
  e.preventDefault();
  e.stopPropagation();
}

export function TooltipIcon(props: PopoverProps) {
  return (
    <Popover {...props}>
      {/* Check premium plan crown alignment if you change the height */}
      <div style={{ height: 20 }} onClick={interceptClickEvents}>
        {props.children}
      </div>
    </Popover>
  );
}
